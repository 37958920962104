// @ts-nocheck
 import {defineComponent} from 'vue'
import {
  SearchByDoctorTab,
  SearchByLocationTab,
  Option,
  SharedSearchQueryParams
} from '@/types'
import FormInput from '@molecules/Form/FormInput.vue'
import HoneyPot from '@molecules/Form/HoneyPot.vue'
import FormMultiSelect from '@molecules/Form/FormMultiSelect.vue'
import TargetSvg from '@atoms/TargetSvg.vue'
import { getBaseUrl } from '@/site.config.ts'
const geo_options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000
}
function handInTheHoney(honeyPotUniqueId): boolean {
  const input = document.getElementById(honeyPotUniqueId) as HTMLInputElement
  return !!input.value.trim()
}
export default defineComponent({
  components: {
    FormInput,
    FormMultiSelect,
    TargetSvg,
    HoneyPot
  },
  props: {
    section: {
      required: true,
      type: Object as () => SearchByDoctorTab | SearchByLocationTab
    },
    networks: {
      required: false,
      type: Array as () => Option[]
    },
    countyStates: {
      required: false,
      type: Array as () => string[]
    },
    currentNetwork: {
      required: false,
      type: Object as () => Option
    },
    zipCode: {
      required: false,
      type: String
    },
    geolocationAvailable: {
      type: Boolean,
      required: true
    },
    authUser: {
      type: Boolean,
      required: true
    },
    hideNetworkDropdown: {
      type: Boolean,
      required: true
    },
    authParams: {
      type: Object,
      required: true
    },
    configQueryParams: {
      type: Object,
      required: true
    },
    serviceErrorOccurred: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      honeyPotUniqueId: '' as string,
      zipValue: this.zipCode,
      networkValue: this.currentNetwork as Option | null,
      provider: '',
      npiNumber: '',
      doctorLicense: '',
      doctorPhoneNumber: '',
      submittingZip: false as boolean,
      submittingCounty: false as boolean,
      coordsRequested: false as boolean,
      gettingCoords: false as boolean,
      searchByDoctorName: true as boolean,
      searchByDoctorLicense: false as boolean,
      searchByDoctorAddress: false as boolean,
      searchByDoctorPhoneNumber: false as boolean,
      searchByZip: true as boolean,
      searchByCounty: false as boolean,
      countyValue: '',
      validationMode: 'eager',
      filteredCounties: [],
      hideValidationMessage: false,
      countyValidationMessage: 'Please enter at least 3 letters for county search',
      countyValidations: { required: true, regex: '^[A-Za-z,]+$' },
      zipcodeSubmitted:false,
      stateDropDownOptions: [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
        { value: 'DC', label: 'District of Columbia' },
        { value: 'GU', label: 'Guam' },
        { value: 'MH', label: 'Marshall' },
        { value: 'MP', label: 'Northern' },
        { value: 'PW', label: 'Palau' },
        { value: 'PR', label: 'Puerto Rico' },
        { value: 'VI', label: 'Virgin ' }
      ],
      chooseStateLabel: 'Choose State',
      doctorStateValue: null as null|Option,
      doctorStreetaddress: null,
      doctorCity: null
    }
  },
  computed: {
    isZipCodeValid(){    
      if(this.zipValue.length === 5 || this.zipValue.length === 10)
        return true
      else
        return false    
    },
    sharedQueryParams(): SharedSearchQueryParams {
      const storedQuery = this.$store.state.context.authParams
      return {
        //@ts-ignore
        ...(!this.networkValue?.value ? this.authParams : null), // only pass authorized user parameters when a network is not selected
        ...(this.networkValue?.value && { networkId: this.networkValue.value }),
        ...this.provider && { providerName: this.provider },
        ...this.npiNumber && { npi : this.npiNumber },
        ...this.doctorLicense && { licenseNo: this.doctorLicense },
        ...this.doctorPhoneNumber && { phoneNumber : this.doctorPhoneNumber },
        ...this.doctorStreetaddress && { doctorStreetaddress : this.doctorStreetaddress },
        ...this.doctorCity && { doctorCity : this.doctorCity },
        ...this.doctorStateValue && { doctorState : this.doctorStateValue?.value },
        ...this.searchByDoctorAddress && { isDoctorAddressSearch : this.searchByDoctorAddress },
        ...this.configQueryParams,
        tieredView: storedQuery.tieredView ? storedQuery.tieredView : 'false',
        sortByZipClass: storedQuery.sortByZipClass ? storedQuery.sortByZipClass : 'true'
      }
    },
    countiesList() {
      //@ts-ignore
      return this.countyValue && this.countyValue.length >= 3 ? this.countyStates : []
    }
  },
  methods: {
    handleZipInput() {
      this.$emit('zipInput', this.zipValue)
    },
    handleNetworkInput() {
      this.$emit('networkInput', this.networkValue)
    },
    handleDoctorStateInput() {
      this.$emit('doctorStateInput', this.doctorStateValue)
    },
    handleSearchByCountyClick() {
      this.searchByZip = false
      this.searchByCounty = true
    },
    handleSearchByZipClick() {
      this.searchByZip = true
      this.searchByCounty = false
    },
    handleDoctorClick() {
      this.searchByDoctorLicense = true
      this.searchByDoctorName = false
      this.searchByDoctorAddress = false
      this.searchByDoctorPhoneNumber = false
      this.provider = ''
      this.networkValue = null
      this.doctorPhoneNumber = ''
      this.doctorStreetaddress = null
      this.doctorCity = null
      this.doctorStateValue = null
    },
    handleDoctorNameClick() {
      this.searchByDoctorLicense = false
      this.searchByDoctorName = true
      this.searchByDoctorAddress = false
      this.searchByDoctorPhoneNumber = false
      this.npiNumber = ''
      this.doctorLicense = ''
      this.doctorPhoneNumber = ''
      this.doctorStreetaddress = null
      this.doctorCity = null
      this.doctorStateValue = null
    },
    handleSearchByDoctorAddressClick() {
      this.searchByDoctorLicense = false
      this.searchByDoctorName = false
      this.searchByDoctorAddress = true
      this.searchByDoctorPhoneNumber = false
      this.npiNumber = ''
      this.doctorLicense = ''
      this.provider = ''
      this.networkValue = null
      this.doctorPhoneNumber = ''
    },
    handleSearchByDoctorPhoneNumberClick() {
      this.searchByDoctorLicense = false
      this.searchByDoctorName = false
      this.searchByDoctorAddress = false
      this.searchByDoctorPhoneNumber = true
      this.npiNumber = ''
      this.doctorLicense = ''
      this.provider = ''
      this.networkValue = null
      this.doctorStreetaddress = null
      this.doctorCity = null
      this.doctorStateValue = null
    },
    resetValues() {
      this.doctorStreetaddress = null
      this.doctorCity = null
      this.doctorStateValue = null
      this.provider = ''
      this.npiNumber = ''
      this.doctorLicense = ''
      this.doctorPhoneNumber = ''
    },
    async handleFilterResults() {
      const inputVal = this.countyValue.trim().toLowerCase();
      if (inputVal.length >= 3) {
        this.filteredCounties = this.countiesList.filter(
          county => county.toLowerCase().startsWith(inputVal)
        );
      } else {
        this.filteredCounties = [];
      }
    },
    uniqueDisabledCheck(input): boolean {
      switch (input) {
      case 'doctorLicense':
        return !!this.npiNumber
      case 'npiNumber':
        return !!this.doctorLicense
      default:
        return false
      }
    },
    async submit(e) {
      const isValid = await (this.$refs.observer as any).validate()
      if(!this.isZipCodeValid)
        return
      if (handInTheHoney(this.honeyPotUniqueId)) return
      this.coordsRequested = false
      this.submittingCounty = false
      this.submittingZip = true  
      if (isValid) {
        const query = {
          zip: this.zipValue.substring(0, 5),
          ...this.sharedQueryParams
        }
        parent.postMessage('showFormSubmitSuccessAlert', '*')
        
        this.$router.push({
          path: `${getBaseUrl(this.$route.params)}/results`,
          query: query
        })


      } else {
        const firstErrorEl: HTMLInputElement|null = this.$el.querySelector('.error')
        if (firstErrorEl) {
          firstErrorEl.focus()
        }
      }
      this.submittingZip = false
    },
    async handleDoctorAddressSearch() :void {
      const isValid = await (this.$refs.observer as any).validate()
      this.coordsRequested = false
      this.submittingCounty = false
      this.submittingZip = true 
      if (isValid) {
        const query = {
          zip: this.zipValue.substring(0, 5),
          ...this.sharedQueryParams
        }
        parent.postMessage('showFormSubmitSuccessAlert', '*')
        
        this.$router.push({
          path: `${getBaseUrl(this.$route.params)}/results`,
          query: query
        })
        this.resetValues()

      } else {
        const firstErrorEl: HTMLInputElement|null = this.$el.querySelector('.error')
        if (firstErrorEl) {
          firstErrorEl.focus()
        }
      }
      this.submittingZip = false
    },
    async getCountySearch(): void {
      this.submittingCounty = true
      const isValid = await (this.$refs.observer as any).validate()
      if (isValid) {
        const query = {
          ...this.sharedQueryParams,
          state: this.countyValue && this.countyValue.split(',')[1],
          county: this.countyValue && this.countyValue.split(',')[0]
        }
        parent.postMessage('showFormSubmitSuccessAlert', '*')
        this.$router.push({
          path: `${getBaseUrl(this.$route.params)}/results`,
          query: query
        })
      } else {
        const firstErrorEl: HTMLInputElement|null = this.$el.querySelector('.error')
        if (firstErrorEl) {
          firstErrorEl.focus()
        }
      }
      this.submittingCounty = false
    },
    getUserCoords(): void {
      if (handInTheHoney(this.honeyPotUniqueId)) return
      this.coordsRequested = true
      this.gettingCoords = true
      this.$nextTick(async () => {
        const isValid = await (this.$refs.observer as any).validate()
        if (isValid) {
          navigator.geolocation.getCurrentPosition(
            this.geo_success,
            this.geo_error,
            geo_options
          )
        } else {
          this.gettingCoords = false
          const firstErrorEl: HTMLInputElement|null = this.$el.querySelector('.error')
          if (firstErrorEl) {
            firstErrorEl.focus()
          }
        }
      })
    },
    geo_success(position) {
      const query = {
        latitude: position.coords.latitude.toString(),
        longitude: position.coords.longitude.toString(),
        ...this.sharedQueryParams
      }
      this.gettingCoords = false
      this.$router.push({
        path: `${getBaseUrl(this.$route.params)}/results`,
        query: query
      })
    },
    geo_error() {
      alert('Sorry, no position available.')
      this.gettingCoords = false
    }
  },
})
