<template>
  <!-- <div></div> -->
  <GMapMap
    ref="mapRef"
    :center="{lat:38.850033, lng:-87.6500523}"
    :zoom="4"
    :options="options">
    <GMapMarker
      v-for="(location, i) in providers"
      :key="i"
      tabindex="0"
      :icon="setIcon(location)"
      :label="{ color: '#000', fontSize: '14px', text: `${setConditionalProviderLabel(location)}`, title:`${location.businessName}` }"
      :clickable="true"
      :z-index="currentProvider === location ? 9999 : (999 - i)"
      :position="createLatLng(location)"
      @click="selectLocation(location)"/>
    <GMapInfoWindow
      v-if="currentProvider"
      :key="`info_window_${infoWindowKey}`"
       style="padding-right: 0px;
          padding-bottom: 0px;
          max-width: 648px;
          max-height: 217px;
          min-width: 0px;
      }"
      :options="infoOptions"
      :opened="infoWinOpen"
      :position="infoWindowPos"
      @closeclick="infoWinOpen=false">
      <map-info-window
        :provider="currentProvider"
        :labels="labels"/>
    </GMapInfoWindow>
  </GMapMap>
</template>
<script lang="ts">
  import { Provider, SearchResultCardLabels } from '@/types'
  import { eventBus } from '@/main'
  import mapStyles from '@/ts/helpers/map.styles.ts'
  import MapInfoWindow from '@molecules/MapInfoWindow.vue'
   import {defineComponent} from 'vue'
  import { LatLng } from '@/types'
  import { generateIconUrl } from '@/ts/helpers/map-svg.ts'
  export default defineComponent({
    name: 'ProviderLocatorGoogleMap',
    components: {
      MapInfoWindow
    },
    props: {
      modelValue: {
        type: Boolean,
        required:true
      },
      providers: {
        required: true,
        type: Array as ()=> Provider[]
      },
      currentProvider: {
        required: false,
        type: Object as () => Provider
      },
      open: {
        required: true,
        type: Boolean
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      }
    },
    data() {
      return { 
        googleMap: null,
        infoWindowKey: 0,
        options: {
          styles: mapStyles,
          zoomControl: !!this.open,
          mapTypeControl: !!this.open,
          streetViewControl: !!this.open,
          fullscreenControl: !!this.open,
        },
        iconTiered: document.querySelector('#map_marker_tiered') as SVGSVGElement,
        iconBasic: document.querySelector('#map_marker_basic') as SVGSVGElement
      }
    },
    computed: {
      infoWinOpen: {
        get(): boolean {
          return this.modelValue
        },
        set(newVal: boolean) {
          this.$emit('update:modelValue', newVal)
        }
      },
      infoWindowPos(): LatLng {
        if (!this.currentProvider) return { lat: 0,lng: 0 } 
        return this.createLatLng(this.currentProvider)
      },
      infoOptions(){ 
        if (!this.currentProvider) return
        return {
          pixelOffset: {
            width: 0,
            height: this.currentProvider.tieredLocation ? -53 : -35
          }
        }
      }
    },
    watch: {
      currentProvider: {
        handler(newVal){
        if (newVal && this.infoWinOpen) {
          this.toggleInfoWindow(newVal)
        }
      },
      deep:true
      },
    },
    methods: {
      selectLocation(provider: Provider, emit: boolean = true): void {
        this.infoWinOpen = true
        if (emit){this.$emit('setCurrentProvider', provider)}
        if (provider === this.currentProvider) {
          this.toggleInfoWindow(provider)
        }
      },
      focusCurrentLocation(): void {
        this.$nextTick(() => {
          setTimeout(() => {
            const currentProviderTitle: HTMLElement|null = this.$el.querySelector('.provider-info-window')
            if (currentProviderTitle){
              const focusable: NodeListOf<HTMLElement> = currentProviderTitle.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
              focusable[0].focus()
            }
          }, 500)
        })
      },
      createLatLng(provider: Provider): LatLng {
        return { lat: provider.latitude, lng: provider.longitude }
      },
      openInfoWindow(provider: Provider) {
        this.infoWinOpen = false
        this.$nextTick(() => { // center an already selected map marker when clicked
          this.infoWinOpen = true
        })
      },
      panToLatLng(provider: Provider) {
        const position = this.createLatLng(provider)
        //@ts-ignore
        this.googleMap.panTo(position)
      },
      toggleInfoWindow(provider: Provider): void {
        this.openInfoWindow(provider)
        this.panToLatLng(provider)
        this.infoWindowKey ++
      },
      setIcon(location: Provider): any {
        const scale = 35
        const icons = this.$store.state.globals.microsite.icons.mapMarkers
        const tieredIcon = icons.mapIconTiered.url
        const defaultIcon = icons.mapIconBasic.url
        const OGurl = location.tieredLocation ? tieredIcon : defaultIcon
        const styledUrl = location.tieredLocation ? generateIconUrl(this.iconTiered) : generateIconUrl(this.iconBasic)
        return { 
          url: styledUrl ? styledUrl : OGurl,
          scaledSize: { width: scale, height: scale },
          labelOrigin: { x: scale/2, y: scale+15 }
        }
      },
      setConditionalProviderLabel(provider):string {
        const businessName = this.currentProvider.businessName || ' '
        return this.currentProvider === provider ? businessName : ' - '
      }
    },

    mounted(): void {
      if (!this.providers.length || !this.currentProvider) return
      eventBus.$on('forceMapInfoWindowOpen', () => {
        this.selectLocation(this.currentProvider, false)
        this.focusCurrentLocation()
      })
      //@ts-ignore     
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.googleMap = map
        const bounds = new window.google.maps.LatLngBounds()
        let providerList 
        let boundsBuffer
        if (!this.open) {
          boundsBuffer = .005
          providerList=[this.currentProvider]
        } else {
          providerList = this.providers
          boundsBuffer = .001
          if (providerList.length === 1) {
            boundsBuffer = .005
          }
        }
        for (const location of providerList) {
          bounds.extend(this.createLatLng(location))
        }
        const extendPoint1 = new  google.maps.LatLng(
          bounds.getNorthEast().lat() + boundsBuffer,
          bounds.getNorthEast().lng() + boundsBuffer
        )
        const extendPoint2 = new  google.maps.LatLng(
          bounds.getNorthEast().lat() - boundsBuffer,
          bounds.getNorthEast().lng() - boundsBuffer
        )
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
        map.fitBounds(bounds)
        map.panToBounds(bounds)
      })
    },
    beforeDestroy () {
      eventBus.$off('forceMapInfoWindowOpen', () => {
        this.openInfoWindow
      })
    },
  })
</script>
