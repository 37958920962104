<template>
  <div
    v-if="appLoaded"
    id="app"
    :class="[{ie: isIE()},{framed:framed}, `${languageSelect.value}`, theme]">
    <app-header
      v-if="!framed"
      :key="`header-${key}`"
      :language-select="languageSelect"
      :selected-font-size="selectedFontSize"
      @fontSizeSelected="selectFontSize"
    />
    <div
      v-if="!framed"
      class="themed-strip"
      :style="globalTheme.borderColor" />
    <service-error
      v-for="(error, i) in serviceErrorsDev"
      :key="'service-error-' + i"
      :error-obj="{ ...error }"
    />
    <app-section
      v-if="serviceErrors.length"
      class="_ptxs _pbxs">
      <div column="12">
        <response-flash-notification
          v-for="(error, i) in serviceErrors"
          :key="'flash-service-error-' + i"
          :error-obj="{ ...error }"
        />
      </div>
    </app-section>
    <div
      id="leave-page-label"
      aria-hidden="true"
      class="sr-only">
      <template v-if="isSpanish()">
        Se abre en una nueva ventana
      </template>
      <template v-else>
        opens in a new window
      </template>
    </div>
    <internet-explorer-message v-if="isLessThanEqualToIe10"/>
    <router-view
      v-else
      :key="`router-${key}`"
      :framed="framed"
      class="page" />
    <app-footer
      v-if="!framed"
      :key="`footer-${key}`"/>
    <confirm-leave-app-modal v-if="confirmingExit" />
  </div>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import ConfirmLeaveAppModal from '@molecules/ConfirmLeaveAppModal.vue'
  import AppFooter from '@organisms/Footer.vue'
  import AppHeader from '@organisms/Header.vue'
  import InternetExplorerMessage from '@/views/InternetExplorerMessage.vue'
  import { ServiceResponseMessage, ColorOverridePaylod, AuthorizedUserParams, GlobalDispatchPayload, ResponseMessagesFetchPayload } from '@/types'
  import { getBaseUrl, _prepareQueryString } from '@/site.config.ts'
  import { eventBus } from '@/main'
  import { debounce } from 'lodash'
  import { isIE } from '@/ts/helpers/IE.ts'
  import { caseInsensetiveQueryKeyMatch } from '@/ts/helpers'
  import { updateAdobeDigitalData } from '@/ts/adobe-analytics/index.ts'
  import AppSection from './components/_global/AppSection.vue'
  import ServiceError from './components/_global/ServiceError.vue'
  import ResponseFlashNotification from './components/_global/ResponseFlashNotification.vue'

  export default defineComponent({
    name: 'App' as string,
    components: {
      AppFooter,
      AppHeader,
      ConfirmLeaveAppModal,
      InternetExplorerMessage,
      AppSection,
      ServiceError,
      ResponseFlashNotification
    },
    data() {
      return {
        printing: false,
        appLoaded: false,
        key: 0,
        selectedFontSize: 'medium' as string,
        confirmingExit: false as boolean
      }
    },
    computed: {
      isLessThanEqualToIe10() {
        return navigator.userAgent.indexOf('MSIE') >= 0
      },
      clientID(): string | undefined {
        if(!this.$route.params.clientID) return this.$route.params.clientID
      },
      languageSelect(): any {
        return this.$store.state.locale.languageSelect
      },
      serviceErrorsDev(): ServiceResponseMessage[] {
        return this.$store.state.responseMessages
        .serviceErrors as ServiceResponseMessage[]
      },
      serviceErrors(): ServiceResponseMessage[] {
        return this.serviceErrorsDev.filter(errorObj => !errorObj.devError)
      },
      framed(): boolean {
        return this.$store.state.context.framed
      },
      theme(): string|undefined {
        return this.$store.state.globals.microsite.theme
      }
    },
    watch: {
      $route(to, from) {
        this.$store.dispatch('responseMessages/clearServiceErrors')
        if (from.meta.scheduleChild && to.meta.scheduleChild) return
        this.key += 1
      },
     clientID: {
        handler(clientID): void {
          this.$router.push({
            path: `${getBaseUrl(this.$route.params)}/`
          })
          window.location.reload()
        }
     },
      languageSelect: {
        handler(languageSelect) {
          this.reloadPage(languageSelect.value)
        },
        deep: true
      },
      selectedFontSize(size) {
        const root: HTMLElement | null = document!!.querySelector(':root')
        switch (size) {
        case 'small':
          document.body.classList.add('small')
          document.body.classList.remove('medium')
          document.body.classList.remove('large')
          break
        case 'medium':
          document.body.classList.remove('small')
          document.body.classList.add('medium')
          document.body.classList.remove('large')
          break
        case 'large':
          document.body.classList.remove('small')
          document.body.classList.remove('medium')
          document.body.classList.add('large')
        }
      }
    },
    methods: {
      selectFontSize(val: string): void {
        this.selectedFontSize = val
        eventBus.$emit('forceCardHeightResize')
      },
      reloadPage(): void {
        if(this.$route.params.lang && this.$route.params.lang!=this.languageSelect!.value){
        this.$router
          .replace(
            this.$route.fullPath.replace(
              `/${this.$route.params.lang}`,
              `/${this.languageSelect!.value}`
            )
          )
          .catch(err => {})}
      },
      setFontSizeFromQuery():void {
        const fontSize = this.$route.query.fontSize as string
        if (fontSize) {
          this.selectFontSize(fontSize)
        }
      },
      framedCheck(): void {
        const framed = this.$route.query.framed === 'true' || this.$route.query.embedded === 'true' ? true : false as boolean
        if (framed) {
          this.$store.commit('context/setFramed')
        }
      },
      overrideColor(colorKey: string): void {
        const primaryColorOverride = this.$route.query[colorKey]
        const overRideExists = (primaryColorOverride !== 'undefined') as boolean
        if (this.$route.query[colorKey] && overRideExists) {
          this.$store.commit('globals/overrideColor', {
          colorToOverride: colorKey,
          colorValue: primaryColorOverride
          } as ColorOverridePaylod)
        }
      },
      sendWindowResizeEvent:  debounce(function(): void {
        eventBus.$emit('iframeResizeEvent')
        eventBus.$emit('windowSizeChange', window.innerWidth as number)
      }, 400),
      printPage(): void {
        window.print()
      },
      async storeAuthParams(): Promise<void> {
        const query = this.$route.query
        const networkSetId = caseInsensetiveQueryKeyMatch('networkSetId', query)
        const authParams = {
          ...query.networkDDDisabled && { networkDDDisabled: query.networkDDDisabled as string },
          ...query.groupId && { groupId: query.groupId as string },
          ...query.zip && { zip: query.zip as string },
          ...query.classId && { classId: query.classId as string },
          ...query.classPlanId && { classPlanId: query.classPlanId as string },
          ...query && { packageId: query.packageId as string },
          ...networkSetId && { networkSetId: networkSetId as string },
          tieredView: query.tieredView ? query.tieredView : 'false',
          sortByZipClass: query.sortByZipClass ? query.sortByZipClass : 'true',
          domState: query.domState ? query.domState : ''
        }  as AuthorizedUserParams
        return this.$store.dispatch('context/storeAuthParams', authParams)
      },
      bindIframeListener(): void {
        if (!this.framed) return
        //@ts-ignore
        const eventMethod = window.addEventListener
          ? 'addEventListener'
          : 'attachEvent'
        const eventer = window[eventMethod]
        const messageEvent = eventMethod === 'attachEvent'
          ? 'onmessage'
          : 'message'
        eventer(messageEvent,  (e) => {
          const data = (e.data || e.message)
          if (typeof data === 'string') {
            if (data.startsWith('fontSizeSelected') ) {
              this.selectFontSize(data.split(':')[1])
            } else if (data.startsWith('localeSelected')) {
              this.$store.commit(
                'locale/changeSelectedLanguage',
                data.split(':')[1]
              )
            }
          }
        })
        parent.postMessage('iframeBound', '*')
      },
      async getLocaleDependentState(language: string): Promise<void> {
        await this.$store.dispatch(
          'responseMessages/getServiceresponseMessages',
        {
          lang: language || 'en',
          test: this.$route.query.content === 'test'
        } as ResponseMessagesFetchPayload
        )
        await this.$store.dispatch('brandLogos/getBrandLogos', {
          lang: language || 'en',
          test: this.$route.query.content === 'test'
        })
        await this.$store
          .dispatch('globals/getGlobals', {
          clientID: this.$route.params.clientID,
          lang: language|| 'en',
          test: this.$route.query.content === 'test'
          } as GlobalDispatchPayload)
          .then(() => {
            this.appLoaded = true
          })
      },
      isIE
    },
    async created(): Promise<void> {
      this.$store.commit('flagAppAsLoaded') // router needs this info for adobe analytics to do some things in the correct order
      await this.storeAuthParams() // set user context, including auth params
      await this.$store.dispatch('responseMessages/clearServiceErrors')
      await this.getLocaleDependentState(this.$route.params.lang || 'en')
      const colorOverrideChecks = [
        'primaryColor',
        'secondaryColor',
        'tertiaryColor',
        'googleMapTieredProviderIconColor'
      ]
      colorOverrideChecks.map(colorKey => this.overrideColor(colorKey))
      this.framedCheck()
      this.setFontSizeFromQuery()
      eventBus.$on('openConfirmModal', () => {
        this.confirmingExit = true
      })
      eventBus.$on('closeExitConfirmation', () => {
        this.confirmingExit = false
      })
      eventBus.$on('printPage', () => {
        this.printPage()
      })
      window.addEventListener('resize', this.sendWindowResizeEvent)
      window.addEventListener('orientationchange', this.sendWindowResizeEvent)
      this.bindIframeListener()
    }
  })
</script>
